import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('distances', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`distances/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('distances', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`distances/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`distances/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        
        warehouses: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('warehouses', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        transporters: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('transporters', { params: params })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        markets: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('markets', { params: params })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    }
}
